/**
 * This file is used only to determine if Angular platform should be run in production mode
 * or not. All other environment related properties are set externally and accessed using EnvironmentService.
 */
export const environment = {
  name: 'test',
  production: false,
  shouldEncrypt: true,
  appHostUrl: 'https://test.getihub.com',
  authModuleApiUrl: 'https://test-user.getihub.com/user',
  usersModuleApiUrl: 'https://test-user.getihub.com/user',
  userModuleApiUrl: 'https://test-user.getihub.com/user',
  organizationModuleApiUrl: 'https://test-organization.getihub.com/organization',
  platformModuleApiUrl: 'https://test-platform.getihub.com/platform',
  nmmModuleApiUrl: 'https://test-nmm.getihub.com/nmm',
  notificationModuleApiUrl: 'https://test-nmm.getihub.com/nmm',
  dmmModuleApiUrl: 'https://test-document.getihub.com/dmm',
  documentModuleApiUrl: 'https://test-document.getihub.com/dmm',
  timesheetModuleApiUrl: 'https://test-timesheet.getihub.com/timesheet',
  tshModuleApiUrl: 'https://test-timesheet.getihub.com/timesheet',
  emmModuleApiUrl: 'https://test-emm.getihub.com/emm',
  jwtClientId: 'getihub_ui',
  jwtClientSecret: '217YlCPsF7O9OCNK3pLfESdtf6jKONYoXc2Xx8TJTCi2WEZs49',
  azureAuthEnabled: true,
  azureAuthClientId: '8cf9379b-4262-4f96-9826-8a299c65b4d2',
  azureAuthTenantId: '03f3fe41-af79-4720-bd8c-d01a63b1a919',
  firebase: {
      apiKey: 'AIzaSyCeb6NNsxMpCCFIzWTkZ4lAOjQ90vLmo9I',
      authDomain: 'getihubpushnotificationserver.firebaseapp.com',
      databaseURL: 'https://getihubpushnotificationserver.firebaseio.com',
      projectId: 'getihubpushnotificationserver',
      storageBucket: '',
      messagingSenderId: '334010698889',
      appId: '1:334010698889:web:4eefbe6019c9d55bb44a58',
      measurementId: 'G-V1B58XGG44',
  },
  logToELK: false,
  logstashHostUrl: 'https://test.getihub.com:33333',
  firebaseVapidKey: 'BGmzUBZrN8m57tcNDRfu4IC7BzkG2W2qPiWG6jMkgi0LJt9yl6KvQRDKEcKiLn6mnEiBZzwFnzxqkeuirwIBpzs',
  googleTagManagerTrackingId: '',
  clarityProjectId: '',
};
